$night: #171c28;
$black: #36363c;
$dark-grey: #444452;
$grey: #777777;
$blue: #007bff;
$slate: #afafbf;
$off-white: #e7e7e7;
$white: #ffffff;
$green: #bae67e;
$purple: #7d0ce8;
$red: #ff0000;
$orange: #e8850c;
$yellow: #ffdc00;
$accent: #ffcc66;
// Fonts
$font-stack: 'Source Sans Pro', system, system-ui, -apple-system,
  BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif;
$Apercu: 'Apercu', $font-stack;
// Media queries
$fifteen-inch: 1440px;
$thirteen-inch: 1280px;
$desktop-width: 1024px;
$little-desktop: 850px;
$tablet-width: 768px;
$weird-not-tablet-width: 630px;
$phablet-width: 550px;
$mobile-width: 480px;
$small-mobile-width: 360px;
$tiny-mobile-width: 330px;

@mixin transition {
  transition: all 0.2s ease-in-out;
}

@mixin thirteen {
  @media screen and (max-width: #{$thirteen-inch}) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin little-desktop {
  @media screen and (max-width: #{$little-desktop}) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin weird-medium {
  @media screen and (max-width: #{$weird-not-tablet-width}) {
    @content;
  }
}

@mixin phablet {
  @media screen and (max-width: #{$phablet-width}) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin small-mobile {
  @media screen and (max-width: #{$small-mobile-width}) {
    @content;
  }
}

@mixin tiny-mobile {
  @media screen and (max-width: #{$tiny-mobile-width}) {
    @content;
  }
}