.skills {
    .skillz {
      display: flex;
      justify-content: space-between;
      @include weird-medium {
        flex-wrap: wrap;
      }
      &__category {
        @include weird-medium {
          width: 47%;
          margin-right: 5px;
          margin-bottom: 25px;
        }
        &__label {
          font-weight: 700;
          color: $black;
          text-transform: uppercase;
        }
        &__item {
          margin: 3px 0;
          font-size: 0.9rem;
        }
      }
    }
  }