.header-wrapper {
    width: 80vw;
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: left;
    
    h1 {
      font-weight: 300;
      color: #444452;
      line-height: 1.5;
      font-family: Apercu,"Source Sans Pro",system,system-ui,-apple-system,BlinkMacSystemFont,Roboto,Helvetica,Arial,sans-serif;
    }
    h3{
      color: #444452;
      line-height: 1.5;
      font-size: 1.25rem;
      font-weight: 400;
      font-family: Apercu,"Source Sans Pro",system,system-ui,-apple-system,BlinkMacSystemFont,Roboto,Helvetica,Arial,sans-serif;
    }
    .email{
      box-shadow: inset 0 -3px 0 #007bff;
      transition: all .2s ease-in-out;
      font-weight: 400;
      text-decoration: none;
      display: inline-block;
      padding: 2px 3px;
      text-decoration: none;
      -webkit-box-shadow: inset 0 -3px 0 #007bff;
    }
  
    span {
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      font-family: Apercu,"Source Sans Pro",system,system-ui,-apple-system,BlinkMacSystemFont,Roboto,Helvetica,Arial,sans-serif;
    }
  
  
    h2 {
      font-size: 1rem;
      text-align: center;
      @include media(">=phone", "<lgphone") {
        font-size: 14px;
      }
      @include media(">=lgphone", "<tablet") {
        font-size: 16px;
      }
      @include media(">=tablet", "<desktop") {
        font-size: 16px;
      }
    }
    .heading-wrapper {
      h1 {
        font-size: 2rem;
        text-align: left;
        line-height: 20px;
        @include media(">=phone", "<lgphone") {
          font-size: 1.5rem;
          line-height: 10px;
        }
        @include media(">=lgphone", "<tablet") {
          font-size: 1.5rem;
          line-height: 10px;
        }
        @include media(">=tablet", "<desktop") {
          font-size: 2.5rem;
        }
      }
    }
    p {
      width: 50%;
      text-align: center;
      overflow: hidden;
      @include media(">=phone", "<lgphone") {
        font-size: 12px;
        width: 100%;
      }
      @include media(">=lgphone", "<tablet") {
        font-size: 12px;
        width: 100%;
      }
      @include media(">=tablet", "<desktop") {
        font-size: 14px;
        width: 100%;
      }
    }
  }
  